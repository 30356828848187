import api from '@/api/api'
import axiosQueue from '@/components/ManualConsole/axiosQueue.js';

export const consoles = {
  sendCMDPhoropter,
  getStatePhoropter,
  sendCMDScreen,
  getStateScreen,
  getCapabilitiesPhoropter,
  getCapabilitiesScreen,
  getStateLensmeter,
  getImage,
  getAllImage,
  getSettings,
  updateSettings,
  setPhoropterRelay,
  getBoxInfo,
  setScreenRelay,
  sendCMDPhoropterTEST
}


function getBoxInfo(ip) {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: `${ip}/`,
  });
}

function setPhoropterRelay(params, ip) {
  return axiosQueue.enqueueRequest({
    method: 'post',
    url: `${ip}/phoropter/relay`,
    data: params,
  });
}

function getSettings() {
  return new Promise((resolve, reject) => {
    api
      .get(`user/console-settings`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
function updateSettings(params) {
  return new Promise((resolve, reject) => {
    api
      .put(`user/console-settings`, params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function sendCMDPhoropter(params, ip, postiveCylinder, vp) {
  if (!vp) {
    params.left_state.addition = 0;
    params.right_state.addition = 0;
  }
  if (postiveCylinder) {
    params.left_state.cylinder = params.left_state.cylinder > 0 ? -params.left_state.cylinder : params.left_state.cylinder;
    params.right_state.cylinder = params.right_state.cylinder > 0 ? -params.right_state.cylinder : params.right_state.cylinder;
  }

  return axiosQueue.enqueueRequest({
    method: 'post',
    url: `${ip}/phoropter/state`,
    data: params,
  });
}

function getStatePhoropter(ip) {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: `${ip}/phoropter/state`,
  });
}

function getAllImage() {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: 'https://optochart.oceanwork.fr/optotype/getall',
  });
}

function getImage(optotype) {
  return axiosQueue.enqueueRequest({
    method: 'post',
    url: 'https://optochart.oceanwork.fr/optotype/get',
    data: optotype,
  });
}

function getStateLensmeter(ip, id) {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: `${ip}/objective-ref/${id}/state`,
  });
}

function sendCMDScreen(params, ip) {
  return axiosQueue.enqueueRequest({
    method: 'post',
    url: `${ip}/screen/state`,
    data: params,
  });
}

function getStateScreen(ip) {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: `${ip}/screen/state`,
  });
}

function getCapabilitiesPhoropter(ip) {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: `${ip}/phoropter/capabilities`,
  });
}

function getCapabilitiesScreen(ip) {
  return axiosQueue.enqueueRequest({
    method: 'get',
    url: `${ip}/screen/capabilities`,
  });
}

function setScreenRelay(params, ip) {
  return axiosQueue.enqueueRequest({
    method: 'post',
    url: `${ip}/screen/relay`,
    data: params,
  });
}

function sendCMDPhoropterTEST(params, ip, postiveCylinder, vp) {
  if (!vp) {
    params.left_state.addition = 0;
    params.right_state.addition = 0;
  }
  if (postiveCylinder) {
    params.left_state.cylinder = params.left_state.cylinder > 0 ? -params.left_state.cylinder : params.left_state.cylinder;
    params.right_state.cylinder = params.right_state.cylinder > 0 ? -params.right_state.cylinder : params.right_state.cylinder;
  }

  return axiosQueue.enqueueRequest({
    method: 'post',
    url: `${ip}/phoropter/state`,
    data: params,
  });
}

