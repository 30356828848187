<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{ darkModal: $store.state.tools.darkMode }">
          <div class="modal-header">
            <slot name="header">
              {{ title }}
              <div class="d-flex align-items-center">
                <div
                  class="mr-2"
                  v-if="loading">
                  <div class="four"></div>
                </div>
                <div
                  class="close-container"
                  @click="$emit('close')">
                  <i class="fa fa-close" />
                </div>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div v-html="body"></div>
            </slot>
          </div>
          <template>
            <slot name="optional"> </slot>
          </template>

          <div
            v-if="!hideFooter"
            class="modal-footer">
            <slot name="footer">
              <b-button
                variant="danger"
                class="modal-default-button"
                @click="$emit('close')">
                {{ $t('common.cancel') }}
              </b-button>
              <b-button
                variant="success"
                class="modal-default-button"
                @click="$emit('action')">
                {{ $t('common.validate') }}
              </b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Delete',
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFooter: {
      default: false
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
  margin-top: 5%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.close-container {
  cursor: pointer;
  color: #c8ced3;
}
.modal-body {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .modal-container {
    width: 90%;
  }
}
</style>
