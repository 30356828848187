<template>
  <div>
    <b-card no-body>
      <b-tabs
        card
        class="myTabs"
        v-model="tabIndex">
        <b-tab
          style="padding: 20px"
          :title="$t('dongle.title')"
          :title-link-class="linkClass(0)">
          <template v-if="dataLoading">
            <SkeletonLine
              height="400px"
              radius="12px" />
          </template>
          <template v-else>
            <div class="cardTitle d-flex align-items-center justify-content-between flex-wrap"></div>

            <div
              class="line"
              style="display: flex; align-items: center; margin: 0">
              <span class="key">Serial : </span>
              <span style="margin-left: 5px">{{ dongle.serial }}</span>
            </div>

            <div
              class="line"
              style="margin-top: 5px">
              <span class="key">{{ $t('dongle.type') }} : </span>
              <span>{{ dongle.type }}</span>
            </div>

            <div class="line">
              <span class="key">{{ $t('dongle.status') }} : </span>
              <select
                v-model="dongle.status"
                class="inputPatient"
                :style="{
                  'background-color': allStatus.find(el => el.name == dongle.status).color,
                  color: 'white'
                }">
                <option
                  v-for="(statut, index) in allStatus"
                  :key="index"
                  :value="statut.name"
                  :style="{ 'background-color': statut.color }">
                  {{ statut.name }}
                </option>
              </select>
            </div>

            <div
              class="line"
              v-if="dongle.box_id">
              <span class="key">Box : </span>
              <router-link
                style="text-decoration: underline #00a79d"
                :to="`/admin/boxes/${dongle.box_id}`"
                >{{ dongle.box.name }}
              </router-link>
            </div>

            <div class="line">
              <span class="key">{{ $t('dongle.created_by') }}: </span>
              <span>{{ dongle.created_by }}</span>
            </div>
            <div class="line">
              <span class="key">{{ $t('dongle.created_at') }} : </span>
              <span>
                {{ moment(dongle.created_at).format('L') }}
                {{ $t('common.at') }}
                {{ moment(dongle.created_at).format('LT') }}</span
              >
            </div>

            <div
              class="line"
              v-if="dongle.created_at != dongle.updated_at">
              <span class="key">{{ $t('dongle.updated_at') }} : </span>
              <span
                >{{ moment(dongle.updated_at).format('L') }}
                {{ $t('common.at') }}
                {{ moment(dongle.updated_at).format('LT') }}</span
              >
            </div>

            <div
              class="line"
              v-if="dongle.modified_by">
              <span class="key">{{ $t('dongle.updated_by') }} : </span>
              <span>{{ dongle.modified_by }}</span>
            </div>

            <div
              class="line"
              v-if="dongle.deleted_at">
              <span class="key">{{ $t('dongle.deleted_at') }} : </span>
              <span
                >{{ moment(dongle.deleted_at).format('L') }}
                {{ $t('common.at') }}
                {{ moment(dongle.deleted_at).format('LT') }}</span
              >
            </div>

            <div class="d-flex justify-content-end">
              <Vbutton
                :type="dongle.deleted_at ? 'sedcondary' : 'success'"
                @clicked="updateDongle"
                :disabled="dongle.deleted_at">
                <template slot="text">
                  {{ $t('common.validate') }}
                </template>
              </Vbutton>
            </div>
          </template>
        </b-tab>
        <b-tab
          style="padding: 20px"
          :title="$t('nav.logs')"
          :title-link-class="linkClass(1)"
          @click="
            logsLoading = true
            getDongleLogs(dongle.id, 1)
          ">
          <template v-if="logsLoading">
            <div
              style="margin-bottom: 8px"
              v-for="(item, index) in 10"
              :key="index">
              <SkeletonLine
                height="40px"
                radius="12px" />
            </div>
          </template>

          <div v-else>
            <div
              class="logBlock cleanShadow"
              v-for="(log, index) in logs"
              :key="index">
              <div>
                <article class="d-flex align-items-center">
                  <span
                    class="mr-2"
                    style="color: lightslategrey"
                    >{{ moment(log.created_at).format('L') }}::{{ moment(log.created_at).format('LT') }}</span
                  >
                  <span
                    class="badge"
                    :class="'logs_' + log.log_name"
                    >{{ log.log_name }}</span
                  >
                </article>
                <div style="font-weight: bold">{{ log.description }}</div>
              </div>
              <div
                v-for="(props, id) in log.properties"
                :key="id">
                <article
                  class="d-flex"
                  v-if="id != 'ip_address' && id != 'user_agent'">
                  <span class="mr-2">{{ id + ': ' }}</span>
                  <pre class="pre-formatted">{{ props }}</pre>
                </article>
              </div>
            </div>
          </div>

          <section
            v-if="datasResult.last_page != 1"
            style="padding-top: 30px; padding-left: 30px">
            <div
              v-if="datasResult.data && datasResult.data.length > 0"
              class="d-flex align-items-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination cursor">
                  <li
                    class="page-item"
                    :class="{ disabled: datasResult.current_page === 1 }">
                    <a
                      class="page-link"
                      aria-label="Previous"
                      :class="{ darkTableTD: $store.state.tools.darkMode }"
                      @click="toPage(1)">
                      <span aria-hidden="true"> &laquo; </span>
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: datasResult.current_page === 1 }">
                    <a
                      class="page-link"
                      :class="{ darkTableTD: $store.state.tools.darkMode }"
                      aria-label="Previous"
                      @click="toPage(datasResult.current_page - 1)">
                      <span aria-hidden="true"> &lsaquo; </span>
                    </a>
                  </li>

                  <li
                    v-for="(page, index) in pagesItems"
                    :key="index"
                    class="page-item"
                    :class="{ active: page === datasResult.current_page }">
                    <a
                      :class="{ darkTableTD: $store.state.tools.darkMode }"
                      v-if="page !== 0 && page >= 1"
                      class="page-link"
                      @click="toPage(page)">
                      {{ page }}
                    </a>
                  </li>

                  <li
                    class="page-item"
                    id="next"
                    :class="{
                      disabled: datasResult.current_page === datasResult.last_page
                    }">
                    <a
                      id="next"
                      :class="{ darkTableTD: $store.state.tools.darkMode }"
                      class="page-link"
                      aria-label="Next"
                      @click="toPage(datasResult.current_page + 1)">
                      <span aria-hidden="true"> &rsaquo; </span>
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{
                      disabled: datasResult.current_page === datasResult.last_page
                    }">
                    <a
                      :class="{ darkTableTD: $store.state.tools.darkMode }"
                      class="page-link"
                      aria-label="Next"
                      @click="toPage(datasResult.last_page)">
                      <span aria-hidden="true"> &raquo; </span>
                    </a>
                  </li>
                </ul>
                <p
                  :class="{ darkTableTD: $store.state.tools.darkMode }"
                  class="VuePagination__count text-center col-md-12">
                  {{
                    $t('common_client.count', {
                      from: datasResult.from,
                      to: datasResult.to,
                      count: datasResult.total
                    })
                  }}
                </p>
              </nav>
            </div>
          </section>
        </b-tab>
      </b-tabs>
    </b-card>

    <Delete
      v-if="dongleDeleteModal"
      :title="$t('dongle.delete_title')"
      :body="
        $t('dongle.delete_body', {
          dongle: dongle.serial
        })
      "
      @close="dongleDeleteModal = false"
      @action="deleteDongleModel" />
  </div>
</template>

<script>
import { dongles } from '@/api/dongles'
import SkeletonLine from '@/components/Skeleton/SkeletonLine'
import Vbutton from '@/components/Shared/Vbutton'
import notif from '@/utils/notif'
import Delete from '@/components/Shared/Delete'
import { optobase } from '@/api/optobase'

export default {
  components: {
    SkeletonLine,
    Vbutton,
    Delete
  },
  data() {
    return {
      dongle: false,
      tabIndex: 0,
      dataLoading: true,
      page: 1,
      logs: [],
      logsLoading: true,
      datasResult: [],
      dongleDeleteModal: false,
      allStatus: [
        {
          name: 'imported',
          color: '#2980b9'
        },
        {
          name: 'checked',
          color: '#27ae60'
        },
        {
          name: 'linked',
          color: '#2ecc71'
        },
        {
          name: 'improper',
          color: '#c0392b'
        },
        {
          name: 'return',
          color: '#8e44ad'
        }
      ]
    }
  },
  mounted() {
    this.getDongle(this.$route.params.id)
  },
  computed: {
    pagesItems: function () {
      let current = this.page
      let last = this.datasResult.last_page
      let delta = 2
      let left = current - delta
      let right = current + delta + 1
      let range = []
      let pages = []
      let l
      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i)
        }
      }
      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1)
          }
        }
        pages.push(i)
        l = i
      })
      return pages
    }
  },
  methods: {
    getDongle(id) {
      dongles
        .getOneDongle(id)
        .then(response => {
          this.dongle = response.data
          this.dataLoading = false
          this.getDongleImages()
        })
        .catch(error => {
          console.log(error)
          this.dataLoading = false
        })
    },
    getDongleLogs(id, page = 1) {
      dongles
        .getLogs(id, page)
        .then(response => {
          this.logs = response.data.data
          this.datasResult = response.data
          this.logsLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return [this.$store.state.tools.darkMode ? '' : 'bg-white', 'green-siview']
      } else {
        return ['bg-light', 'text-secondary', 'border-bottom']
      }
    },
    deleteDongleModel() {
      dongles
        .deleteDongle(this.dongle.id)
        .then(() => {
          this.getDongle(this.dongle.id)
          this.dongleDeleteModal = false
        })
        .catch(error => {
          notif.showNotif(error.response.data.message, 'error')
        })
    },
    toPage(page) {
      this.page = page
      this.getDongleLogs(this.dongle.id, page)
    },
    updateDongle() {
      dongles
        .update(this.dongle)
        .then(response => {
          notif.showNotif(this.$t('dongle.update_success'), 'success')
          this.dongle = response.data
        })
        .catch(error => {
          if (error.response.data.errors) {
            for (const err in error.response.data.errors) {
              notif.showNotif(error.response.data.errors[err], 'error')
            }
          } else {
            notif.showNotif(error.message, 'error')
          }
        })
    },
    getDongleImages() {
      optobase
        .getDongleById(this.dongle.optobase_id)
        .then(response => {})
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.logBlock {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.pre-formatted {
  white-space: pre-line;
  margin: 0;
  margin-top: 3px;
}

.logs_imported {
  background: rgb(4, 141, 4);
}
.logs_deleted {
  background: tomato;
}
.line {
  margin-bottom: 10px;
  font-size: 120%;
}

.inputPatient {
  padding: 2px;
  border-radius: 4px;
  border: 1px solid lightgray;
  font-size: 100%;
  width: 100px;
  margin-left: 10px;
  padding-left: 3px;
}
.inputPatient[type='text'] {
  background: transparent;
}

.logs_imported {
  background: #2980b9;
}

.logs_checked {
  background: #27ae60;
}
.logs_linked {
  background: #2ecc71;
}
.logs_improper {
  background: #c0392b;
}
.logs_return {
  background: #8e44ad;
}
.logs_deleted {
  background: tomato;
}

.line .key {
  color: var(--siview-green);
}

.link {
  text-decoration: underline;
}
.link:hover {
  text-decoration: underline rgb(26, 26, 26);
}
</style>
