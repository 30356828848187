<template>
  <div>
    <div class="skeleton">
      <div class="tweet-content">
        <div class="tweet-text">
          <div
            class="skeleton-line"
            :style="{ width: width, height: height, borderRadius: radius }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: { default: 'auto' },
    height: { default: '1.5rem' },
    radius: { default: '3px' }
  }
}
</script>
<style>
.tweet-header,
.tweet-text,
.tweet-footer,
.skeleton-avatar,
.skeleton-img {
  -webkit-animation: skeleton 1s ease-in-out forwards infinite;
  animation: skeleton 1s ease-in-out forwards infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.tweet-text {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.skeleton-line {
  height: 1.5rem;
  background-color: #e2e2e2;
  border-radius: 3px;
}

@-webkit-keyframes skeleton {
  0% {
    opacity: 0.2;
    -webkit-transform: translateY(6px) scale(0.98);
    transform: translateY(6px) scale(0.98);
  }
  85%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes skeleton {
  0% {
    opacity: 0.2;
    -webkit-transform: translateY(6px) scale(0.98);
    transform: translateY(6px) scale(0.98);
  }
  85%,
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
</style>
