import api from '@/api/api'

export const dongles = {
  importDongles,
  getDongles,
  getOneDongle,
  getLogs,
  deleteDongle,
  update,
  getScopedData
}

function importDongles(file) {
  let formData = new FormData()
  console.log('file', file)
  formData.append('file', file)
  return new Promise((resolve, reject) => {
    api
      .post('/dongles/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getDongles(perPages, pages, sortBy, query, orderBy) {
  let url = `dongles?limit=${perPages}&page=${pages}&ascending=${sortBy}&byColumn=1&orderBy=${orderBy}`
  if (Object.keys(query).length != 0) {
    url += `&query=${JSON.stringify(query)}`
  }

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getOneDongle(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/dongles/${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getLogs(id, page) {
  return new Promise((resolve, reject) => {
    api
      .get(`dongles/${id}/logs?page=${page}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function deleteDongle(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/dongles/${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
        console.log(error)
      })
  })
}

function update(dongle) {
  return new Promise((resolve, reject) => {
    api
      .put(`/dongles/${dongle.id}`, dongle)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getScopedData() {
  return new Promise((resolve, reject) => {
    api
      .get(`dongles/scoped-data`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}
