<script>
import { Pie } from 'vue-chartjs'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

export default {
  extends: Pie,
  props: {
    values: {},
    labels: {},
    colorValues: { default: false }
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            height: 200,
            width: 200,
            borderWidth: 1,
            borderColor: ['white', 'white', 'white', 'white'],
            hoverBorderColor: 'white',
            backgroundColor: this.colorValues
              ? this.colorValues
              : [
                  'rgba(238, 102, 74, 0.9)',
                  'lightgray',
                  'rgba(83,173,162)',
                  '#3366cc',
                  '#dc3912',
                  '#ff9900',
                  '#109618',
                  '#990099',
                  '#0099c6',
                  '#dd4477',
                  '#66aa00',
                  '#b82e2e',
                  '#316395',
                  '#3366cc',
                  '#994499',
                  '#22aa99',
                  '#aaaa11',
                  '#6633cc',
                  '#e67300',
                  '#8b0707',
                  '#651067',
                  '#329262',
                  '#5574a6',
                  '#3b3eac',
                  '#b77322',
                  '#16d620',
                  '#b91383',
                  '#f4359e',
                  '#9c5935',
                  '#a9c413',
                  '#2a778d',
                  '#668d1c',
                  '#bea413',
                  '#0c5922',
                  '#743411'
                ],
            data: this.values
          }
        ]
      },
      options: {
        onClick: (evt, item) => {
          this.$data._chart.update()
          if (item.length) {
            item[0]._model.outerRadius += 8
            this.$emit('itemDoughnutClicked', this.chartData.labels[item[0]._index])
          } else {
            this.$emit('doughnutClickedOut')
          }
        },

        plugins: {
          datalabels: {
            color: 'white',
            textAlign: 'center',
            font: {
              size: 16
            },
            formatter: value => {
              return value > 0 ? value : ''
            }
          }
        },
        chart: {
          type: 'donut'
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },

  methods: {
    colors(labels) {
      let colorsPicker = []
      for (let i = 0; i < labels.length; i++) {
        colorsPicker.push('#' + Math.floor(Math.random() * 16777215).toString(16))
        console.log(colorsPicker)
      }
      return colorsPicker
    }
  }
}
</script>
