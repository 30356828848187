const getDefaultState = () => {
  return {
    currentShop: null
  }
}

const state = getDefaultState()

const getters = {
  getShopState(state) {
    return {
      currentShop: state.currentShop
    }
  }
}

const actions = {
  setShop({ commit }, params) {
    commit('set_shop', params)
  }
}

const mutations = {
  set_shop(state, params) {
    state.currentShop = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
