import { consoles } from '@/api/console'

const state = {
  examens: [],
  settings: {
    ip: '192.168.75.34',
    box_id: null,
    box: null,
    radius: false,
    debug: false,
    fav_optotypes: [],
    selected_keyboard_profile: 0,
    keyboard_profile: [
      {
        id: 2,
        editable: true,
        name: 'Custom 1',
        keyboard: {}
      },
      {
        id: 3,
        editable: true,
        name: 'Custom 2',
        keyboard: {}
      },
      {
        id: 4,
        editable: true,
        name: 'Custom 3',
        keyboard: {}
      }
    ],
    step: [
      {
        name: 'axis',
        step_list: [1, 3, 5, 10],
        value: 5
      },
      {
        name: 'cylinder',
        step_list: [0.25, 0.5, 1],
        value: 0.25
      },
      {
        name: 'sphere',
        step_list: [0.25, 0.5, 1],
        value: 0.25
      },
      {
        name: 'addition',
        step_list: [0.25, 0.5, 1],
        value: 0.25
      },
      {
        name: 'horizontal_prism',
        step_list: [0.25, 0.5, 1, 5],
        value: 0.5
      },
      {
        name: 'vertical_prism',
        step_list: [0.25, 0.5, 1, 5],
        value: 0.5
      }
    ]
  }
}
const getters = {
  settings: state => {
    // if(state.settings.keyboard_profile.length == 3){
    // 	state.settings.keyboard_profile = defaultKeyboard.concat(state.settings.keyboard_profile)
    // }
    return state.settings
  },
  getExamens: state => {
    return state.examens
  }
}

const actions = {
  getSettings({ commit }) {
    consoles.getSettings().then(settings => {
      console.log(settings.data)
      // if (!Object.keys(settings.data).length) {
      // 	console.log("USING DEFAULT DATA")
      // 	settings.data = state.settings
      // }
      commit('setSettings', settings.data)
    })
  },
  updateSettings({ commit }, params) {
    consoles.updateSettings(params).then(settings => {
      commit('setSettings', settings.data)
    })
  },
  setSettings({ commit }, params) {
    commit('setSettings', params)
  }
}

const mutations = {
  setSettings(state, value) {
    state.settings = value
  },
  setExamens(state, value) {
    state.examens = JSON.parse(JSON.stringify(value))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
