<template>
  <button
    v-b-tooltip.hover
    :title="tooltip"
    class="butt"
    :class="[type, disableButton ? 'disa': '' ]"
    @click.stop="disableButton ? () => null : $emit('clicked', param)">
    <template>
      <slot name="text"></slot>
    </template>
  </button>
</template>

<script>
export default {
  props: {
    type: { required: true },
    param: { default: null },
    tooltip: { default: null },
    disableButton: {default: false}
  }
}
</script>

<style scoped>
.butt {
  box-shadow:
    0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12),
    0 1px 3px 0 rgba(60, 75, 100, 0.2);
  border: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: ease-in-out 0.2s;
  margin-bottom: 10px;
}

.success {
  background: var(--siview-green);
  color: white;
}

.success:hover:enabled {
  background: #018a81;
}

.danger {
  background: #dc3545;
  color: white;
}

.danger:hover:enabled {
  background: #ca0317;
}

.edit {
  background: #005d92;
  color: white;
}

.edit:hover:enabled {
  background: #003b5e;
}

.successOutter {
  background: transparent;
  border: 1px solid var(--siview-green);
  color: var(--siview-green);
}

.editOutter {
  background: transparent;
  border: 1px solid #003b5e;
  color: #003b5e;
}

.orange {
  background: orange;
  color: white;
}

.reset {
  background: rgb(31, 191, 231);
  color: white;
}

/* .successOutter:hover:enabled {
  background: var(--siview-green);
  border: 1px solid var(--siview-green);
  color: white;
} */

.secondary {
  background: rgb(145, 143, 143);
  background: rgb(187, 187, 187);
  color: white;
}

button:disabled,
button[disabled] {
  opacity: 0.65;
}

.disa{
  opacity: .5;    
}
</style>
