import axios from 'axios';

const requestQueue = [];
let isQueueProcessing = false;

function enqueueRequest(config) {
  return new Promise((resolve, reject) => {
    requestQueue.push({ config, resolve, reject });
    if (!isQueueProcessing) {
      processQueue();
    }
  });
}

function processQueue() {
  if (requestQueue.length === 0) {
    isQueueProcessing = false;
    return;
  }

  isQueueProcessing = true;
  const { config, resolve, reject } = requestQueue.shift();

  axios(config)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    })
    .finally(() => {
      processQueue();
    });
}

export default {
  enqueueRequest,
};
