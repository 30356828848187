<template>
  <div>
    <div class="d-flex align-items-center justify-content-end text-right pb-2">
      <slot name="top_buttons"></slot>
      <Vbutton
        type="success"
        :param="null"
        :tooltip="$t('common_client.refresh')"
        @clicked="refreshData()"
        style="margin: 0px 4px 4px 0px"
        id="rfrsh">
        <template slot="text">
          <i
            style="font-size: 16px"
            class="fas fa-sync-alt cursor"
            :class="{ loadBoxe: firstLoading }"
            id="iconRfrsh"></i>
          {{ $t('common_client.refresh') }}
        </template>
      </Vbutton>
    </div>
    <div
      class="forResponsive"
      :class="{ darkTable: $store.state.tools.darkMode }">
      <table
        class="table"
        :class="[classTable, layout ? 'fixedLayout' : 'autoLayout']"
        :style="styleTable">
        <thead>
          <tr
            class="headingTitle"
            :class="{ darkHeading: $store.state.tools.darkMode }">
            <th
              :class="{
                firstID: column === 'id',
                actions: column == 'actions'
              }"
              v-for="(column, index) in columns"
              :key="index"
              :style="{ width: colSizes[index] ? colSizes[index] + 'em' : 'auto' }">
              <div
                class="d-flex align-items-center justify-content-between"
                :style="{cursor: column !== 'actions' && sortable.includes(column) ? 'pointer' : 'default'}"
                @click="column !== 'actions' && sortable.includes(column) ? sortBy(column, 'ASC, DESC', index) : null">
                <div
                  class="forThSorting"
                  >
                  {{ $te(`siview_shared.${column}`) ? $t('siview_shared.' + column) : $t(`${keyTrad}.${column}`) }}
                </div>
                <div
                  v-if="column !== 'actions' && sortable.includes(column)"
                  style="display: flex; flex-direction: column; justify-content: center">
                  <i
                    :class="{
                      selectedSort:
                        selectedOrderBy == column && 1 == $store.getters['vueTableFilters/getState'].ascending
                    }"
                    class="fa-solid fa-caret-up cursor"
                    style="font-size: 110%"></i>
                  <i
                    :class="{
                      selectedSort:
                        selectedOrderBy == column && 0 == $store.getters['vueTableFilters/getState'].ascending
                    }"
                    class="fa-solid fa-caret-down cursor"
                    style="font-size: 110%"></i>
                </div>
              </div>
            </th>
          </tr>
          <tr
            v-if="withSearch"
            class="headingSearch">
            <th
              v-for="(column, index) in columns"
              :key="index">
              <div v-if="column !== 'actions' && columns_filter.includes(column)">
                <template>
                  <slot :name="'search' + column">
                    <input
                      :id="'filter_' + column"
                      v-model="search[column]"
                      @keyup="searchRequest"
                      :placeholder="
                        $te(`siview_shared.${column}`) ? $t('siview_shared.' + column) : $t(`${keyTrad}.${column}`)
                      "
                      class="form-control" />
                  </slot>
                </template>
              </div>
              <div v-else-if="column !== 'actions'">
                <template>
                  <slot :name="'search' + column"> </slot>
                </template>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="datasResult.data && datasResult.data.length > 0 && !firstLoading">
          <tr
            @click="rowClick(result)"
            @click.middle="rowClick(result, true)"
            v-for="(result, index) in datasResult.data"
            :key="index"
            style="cursor: pointer"
            :class="{ selectedRow: rowSelected == result[index] }">
            <td
              v-for="(field, ind) in datas"
              :key="ind"
              :class="[{ darkTableTD: $store.state.tools.darkMode }, 'td_' + field]">
              <a
                @contextmenu="rightClick('id_' + index, result)"
                style="display: flex"
                class="linkTable"
                :class="'id_' + index">
                <template v-if="field != 'secondary_roles'">
                  <slot
                    :name="field"
                    :props="result">
                    {{ result[field] }}
                  </slot>
                </template>
              </a>
            </td>
            <td
              v-if="withActions"
              class="actionsCell"
              style="margin-left: 5px"
              :class="{ darkTableTD: $store.state.tools.darkMode }">
              <div
                v-if="withHoverActions"
                class="inCells d-flex align-items-center"
                style="position: relative">
                <i
                  style="margin-left: 5px"
                  id="icon"
                  class="fas fa-ellipsis-h elips"></i>
                <div class="actionsTd d-flex align-items-center">
                  <template>
                    <slot
                      name="forButtons"
                      :props="result"></slot>
                  </template>
                </div>
              </div>
              <div
                v-else
                class="actionsTdWithoutHover">
                <template>
                  <slot
                    name="forButtons"
                    :props="result"></slot>
                </template>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody v-if="firstLoading">
          <tr v-for="(n, index) in 10">
            <td v-for="(n, index) in columns">
              <div style="padding: 12px">
                <SkeletonLine :key="n" />
              </div>
            </td>
          </tr>
        </tbody>

        <section
          v-if="datasResult.last_page != 1 && !firstLoading"
          style="padding-top: 30px; padding-left: 30px">
          <div
            v-if="datasResult.data && datasResult.data.length > 0"
            class="d-flex align-items-center">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li
                  class="page-item"
                  :class="{ disabled: datasResult.current_page === 1 }">
                  <a
                    class="page-link"
                    aria-label="Previous"
                    :class="{ darkTableTD: $store.state.tools.darkMode }"
                    @click="toPage(1)">
                    <span aria-hidden="true"> &laquo; </span>
                  </a>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: datasResult.current_page === 1 }">
                  <a
                    class="page-link"
                    :class="{ darkTableTD: $store.state.tools.darkMode }"
                    aria-label="Previous"
                    @click="toPage(datasResult.current_page - 1)">
                    <span aria-hidden="true"> &lsaquo; </span>
                  </a>
                </li>

                <li
                  v-for="(page, index) in pagesItems"
                  :key="index"
                  class="page-item"
                  :class="{ active: page === datasResult.current_page }">
                  <a
                    :class="{ darkTableTD: $store.state.tools.darkMode }"
                    v-if="page !== 0 && page >= 1"
                    class="page-link"
                    @click="toPage(page)">
                    {{ page }}
                  </a>
                </li>

                <li
                  class="page-item"
                  id="next"
                  :class="{
                    disabled: datasResult.current_page === datasResult.last_page
                  }">
                  <a
                    id="next"
                    :class="{ darkTableTD: $store.state.tools.darkMode }"
                    class="page-link"
                    aria-label="Next"
                    @click="toPage(datasResult.current_page + 1)">
                    <span aria-hidden="true"> &rsaquo; </span>
                  </a>
                </li>
                <li
                  class="page-item"
                  :class="{
                    disabled: datasResult.current_page === datasResult.last_page
                  }">
                  <a
                    :class="{ darkTableTD: $store.state.tools.darkMode }"
                    class="page-link"
                    aria-label="Next"
                    @click="toPage(datasResult.last_page)">
                    <span aria-hidden="true"> &raquo; </span>
                  </a>
                </li>
              </ul>
              <p
                :class="{ darkTableTD: $store.state.tools.darkMode }"
                class="VuePagination__count text-center col-md-12">
                {{
                  $t('common_client.count', {
                    from: datasResult.from,
                    to: datasResult.to,
                    count: datasResult.total
                  })
                }}
              </p>
            </nav>
          </div>
        </section>
        <div
          class="forOnePageRes"
          v-if="datasResult.last_page == 1 && !firstLoading && datasResult.data && datasResult.data.length > 0">
          {{
            $t('common_client.count', {
              from: datasResult.from,
              to: datasResult.to,
              count: datasResult.total
            })
          }}
        </div>
      </table>
      <div
        v-if="!firstLoading && (!datasResult.data || (datasResult.data && datasResult.data.length === 0))"
        class="noResult">
        {{ $t('common_client.no_results') }}
      </div>
    </div>
    <div
      class="form-inline justify-content-end"
      style="margin-bottom: 15px">
      <div class="">
        <label>{{ $t('common_client.results') }}</label>
        <select
          v-model="getState.perPages"
          @change="changeLimit"
          name="limit"
          class="form-control"
          style="min-width: 65px">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vbutton from '@/components/Shared/Vbutton'
import SkeletonLine from '@/components/Skeleton/SkeletonLine'
export default {
  name: 'V-table',
  components: {
    Vbutton,
    SkeletonLine
  },
  props: {
    columns: { type: Array },
    columns_filter: { type: Array },
    title: { type: String },
    datasResult: {},
    datas: {},
    visiblePageButton: {},
    orderBy: {},
    classTable: { default: null },
    layout: { default: true },
    withActions: { default: true },
    withSearch: { default: true },
    firstLoading: { default: true },
    styleTable: { type: String, default: '' },
    keyTrad: { type: String },
    rowSelected: { default: false },
    withHoverActions: { default: true },
    colSizes: {
      type: Array,
      default() {
        return []
      }
    },
    ascending: { default: 0 },
    sortable: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      query: ''
    }
  },
  beforeMount() {
    if (
      this.$store.getters['vueTableFilters/getState'].subject === null ||
      this.$store.getters['vueTableFilters/getState'].subject !== this.title
    ) {
      this.setSubject(this.title)
      this.setPage(1)
      this.setPerPages(10)
      this.setOrderBy(null)
      this.setFilters({})
      this.setExtraFilter({})
    }
    
    if (this.sortable.length === 0)
      this.sortable = this.columns
  },
  computed: {
    ...mapGetters('vueTableFilters', ['getState']),

    pagesItems: function () {
      let current = this.page
      let last = this.datasResult.last_page
      let delta = 2
      let left = current - delta
      let right = current + delta + 1
      let range = []
      let pages = []
      let l
      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i)
        }
      }
      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1)
          }
        }
        pages.push(i)
        l = i
      })
      return pages
    },
    page: function () {
      return this.$store.getters['vueTableFilters/getState'].page
    },
    search: function () {
      return { ...this.$store.getters['vueTableFilters/getState'].filters }
    },
    byOrder: function () {
      return this.getState.orderBy ? this.$store.getters['vueTableFilters/getState'].orderBy : this.orderBy
    },
    selectedOrderBy: function () {
      if (!this.$store.getters['vueTableFilters/getState'].orderBy) {
        return this.columns[this.columns.findIndex(data => data == this.orderBy)]
      } else {
        return this.columns[
          this.datas.findIndex(data => data == this.$store.getters['vueTableFilters/getState'].orderBy)
        ]
      }
    }
  },
  watch: {
    search: function () {
      return { ...this.$store.getters['vueTableFilters/getState'].filters }
    }
  },
  methods: {
    ...mapActions('vueTableFilters', [
      'setSubject',
      'setFilters',
      'setOrderBy',
      'setAscending',
      'setPage',
      'setPerPages',
      'setOrderBy',
      'setExtraFilter'
    ]),

    searchRequest() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.setPage(1)
        this.setFilters(this.search)
        Object.keys(this.search).forEach(key => this.search[key].toString().trim() == '' && delete this.search[key])

        this.setFilters(this.search)
        this.$emit('pageToogle')
      }, 300)
    },
    toPage(page) {
      this.setPage(page)
      this.$emit('pageToogle')
    },
    changeLimit() {
      this.setPerPages(event.target.value)
      this.setPage(1)
      this.$emit('pageToogle')
    },
    sortBy(column, order, index) {
      let clm = this.datas[index]
      this.setOrderBy(clm)
      this.setAscending(this.getState.ascending === 0 ? 1 : 0)
      this.$emit('pageToogle')
    },
    rowClick(data, middle = false) {
      this.$emit('rowClick', data, middle)
    },
    sublimSearch(elem) {
      return elem
    },
    refreshData() {
      this.setSubject(this.title)
      this.setPage(1)
      this.setPerPages(10)
      this.setOrderBy(null)
      this.setFilters({})
      this.setExtraFilter({})
      this.firstLoading = true
      this.$emit('pageToogle')
    },
    rightClick(element, row) {
      this.$emit('rightClick', element, row)
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 1000px) {
  table {
    width: 100%;
    overflow-x: auto;
    border: none !important;
    font-size: 14px !important;
    display: table !important;
    margin-bottom: 0px !important;
  }
}
.forResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
  background: white;
  border-radius: 8px;
  box-shadow:
    0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12),
    0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.noResult {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 32px;
  color: grey;
}

td {
  padding: 0px !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  border: none !important;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  overflow-x: auto;
  scrollbar-width: none;
}

td::-webkit-scrollbar {
  display: none;
}

.linkTable {
  padding: 12px !important;
}
.linkTable {
  text-decoration: none !important;
  color: unset !important;
}
nav a {
  cursor: pointer;
}

tbody tr:hover {
  background-color: #f7f7f7;
}

.headingTitle {
  background: #f7f7f7;
  color: grey;
  box-shadow: 0 3px 2px -2px lightgray;
}
.headingTitle th {
  padding: 12px !important;
}

.autoLayout {
  table-layout: auto !important;
}

.fixedLayout {
  table-layout: fixed !important;
}

.forCaret {
  display: flex;
  flex-direction: column;
}

tbody tr {
  box-shadow: 0 3px 2px -2px lightgray;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px !important;
}
.table th {
  border-top: none !important;
  font-size: 14px !important;
  box-sizing: border-box;
  border: none !important;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #f7f7f7;
}

input {
  font-size: 95%;
}

.headingSearch {
  box-shadow: 0 3px 2px -2px lightgray;
}

.darkTable {
  background: transparent !important;
  box-shadow: 0px 0px 3px lightgray;
}

.darkHeading {
  background: #20202a !important;
  color: lightgray;
}

.darkTableTD {
  color: white;
}
.darkTable tbody tr:hover {
  background-color: #20202a;
}

.elips {
  display: block;
  color: grey;
}

tbody tr:hover .elips {
  display: none;
  opacity: 0;
}

.actionsTd {
  display: flex;
  opacity: 0;
  padding-top: 10px;
  padding-bottom: 4px;
  padding-left: 4px;
  position: relative;
}

@media screen and (max-width: 1000px) {
  .actionsTd {
    display: flex;
    opacity: 1;
    flex-wrap: wrap;
  }
  #icon {
    display: none;
  }

  .fixedLayout {
    overflow-x: auto;
  }
}

tbody tr:hover .actionsTd {
  opacity: 1;
  /*   flex-wrap: wrap;
 */
}

table {
  overflow: hidden;
}

.forThSorting {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forOnePageRes {
  min-width: 450px;
  padding: 20px;
}

.c-dark-theme table input {
  background-color: #20202a !important;
}
.loadBoxe {
  animation: loadingBoxe 1s linear infinite;
}
@keyframes loadingBoxe {
  to {
    transform: rotate(360deg);
  }
}

table th {
  font-size: 1em !important;
}

table td {
  font-size: 0.9em !important;
}

.actionsCell {
  overflow-x: visible !important;
}

.selectedSort {
  color: #20202a;
}
</style>
