import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilUser,
  cilXCircle,
  cilX,
  cilCheck,
  cilLockLocked,
  cilHouse,
  cilPlus,
  cilMinus,
  cilWarning,
  cilSync,
  cilBarChart,
  cilChevronRight,
  cilChevronLeft,
  cilChevronDoubleRight,
  cilChevronDoubleLeft,
  cilMagnifyingGlass,
  cilReload,
  cilInbox,
  cilPeople,
  cilCaretBottom,
  cilCaretTop,
  cilFile,
  cilSitemap,
  cilDescription,
  cilApplications,
  cilVector,
  cilCode,
  cilLan,
  cilSettings,
  cilMoney,
  cilUserFollow,
  cilAvTimer,
  cilChartLine,
  cilKeyboard,
  cilRouter,
  cilBorderOuter,
  cilCalculator
} from '@coreui/icons'
import { logo } from './logo'
export const iconsSet = Object.assign(
  {},
  {
    cilApplicationsSettings,
    cilDrop,
    cilPuzzle,
    cilMoon,
    cilSpeedometer,
    cilSun,
    logo,
    cilUser,
    cilXCircle,
    cilX,
    cilCheck,
    cilLockLocked,
    cilHouse,
    cilPlus,
    cilMinus,
    cilWarning,
    cilSync,
    cilBarChart,
    cilChevronRight,
    cilChevronLeft,
    cilChevronDoubleRight,
    cilChevronDoubleLeft,
    cilMagnifyingGlass,
    cilReload,
    cilInbox,
    cilPeople,
    cilCaretBottom,
    cilCaretTop,
    cilFile,
    cilSitemap,
    cilDescription,
    cilApplications,
    cilVector,
    cilCode,
    cilLan,
    cilSettings,
    cilMoney,
    cilUserFollow,
    cilAvTimer,
    cilChartLine,
    cilKeyboard,
    cilRouter,
    cilBorderOuter,
    cilCalculator
  }
)
