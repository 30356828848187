<template>
  <div>
    <b-row>
      <b-col lg="6">
        <div>
          <div class="card">
            <div class="card-header">{{ $t('dongle.status') }}</div>
            <div class="card-body">
              <template v-if="!totalLoading">
                <template v-if="scopedData">
                  <DoughnutComponent
                    :values="
                      scopedData.scopeStatus.map(scope => {
                        return scope.count
                      })
                    "
                    :labels="
                      scopedData.scopeStatus.map(scope => {
                        return scope.name
                      })
                    "
                    :width="260"
                    :height="200"
                    :colorValues="
                      scopedData.scopeStatus.map(scope => {
                        return scope.color
                      })
                    " />
                </template>
                <template v-else>
                  <div class="forNodataWrapper">
                    <span>{{ $t('common.no_data') }}</span>
                  </div>
                </template>
              </template>
              <div
                v-else
                class="card-body insideBlock"
                style="height: 240px">
                <div class="four"></div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="6">
        <div>
          <div class="card">
            <div class="card-header">{{ $t('dongle.type') }}</div>
            <div class="card-body">
              <template v-if="!totalLoading">
                <template v-if="scopedData">
                  <DoughnutComponent
                    :values="Object.values(scopedData.scopeType)"
                    :labels="Object.keys(scopedData.scopeType)"
                    :width="260"
                    :height="200" />
                </template>
                <template v-else>
                  <div class="forNodataWrapper">
                    <span>{{ $t('common.no_data') }}</span>
                  </div>
                </template>
              </template>
              <div
                v-else
                class="card-body insideBlock"
                style="height: 240px">
                <div class="four"></div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <VTable
      title="dongle"
      :columns="['id', 'dongle_name', 'serial', 'status', 'created_by', 'actions']"
      :columns_filter="['id', 'dongle_name', 'serial', 'status', 'created_by', 'actions']"
      :datas="['id', 'dongle_name', 'serial', 'status', 'created_by']"
      :datasResult="donglesData"
      :visiblePageButton="5"
      @pageToogle="getAllDongles"
      :orderBy="orderBy"
      classTable="sizeColumn oneActionCol"
      @rowClick="rowClick"
      :firstLoading="firstLoading"
      @rightClick="rightClick"
      keyTrad="dongle"
      :colSizes="[3, 15, 15, 12, 10, 5]">
      <template slot="top_buttons">
        <Vbutton
          class="mt-2"
          type="edit"
          @clicked="importModal = true"
          style="display: flex; margin-top: 0px; margin-right: 10px">
          <template slot="text">
            <i
              class="fa fa-upload mr-2"
              style="margin-top: 2px"
              aria-hidden="true"></i
            >Import dongles
          </template>
        </Vbutton>
      </template>
      <template
        slot="searchstatus"
        slot-scope="row">
        <select
          class="form-control no-border"
          @change="selectSearch($event, 'status')"
          v-model="filterStatus"
          placeholder="Status">
          <option value="">{{ $t('role.all') }}</option>
          <option value="imported">IMPORTED</option>
          <option value="checked">CHECKED</option>
          <option value="linked">LINKED</option>
          <option value="return">RETURN</option>
        </select>
      </template>

      <template
        slot="status"
        slot-scope="row">
        <span
          class="badge"
          :class="'logs_' + row.props.status"
          >{{ row.props.status }}</span
        >
      </template>

      <template
        slot="forButtons"
        slot-scope="row">
        <Vbutton
          type="success"
          :param="row.props.id"
          :tooltip="$t('common.show')"
          @clicked="$router.push({ path: `/dongles/${row.props.id}` })"
          style="margin: 0px 4px 4px 0px">
          <template slot="text">
            <i class="fa fa-search" />
          </template>
        </Vbutton>
      </template>
    </VTable>

    <b-modal
      v-model="importModal"
      size="m"
      hide-footer
      :header-text-variant="$store.state.tools.darkMode ? 'light' : 'default'"
      :body-bg-variant="$store.state.tools.darkMode ? 'dark' : 'default'"
      :header-bg-variant="$store.state.tools.darkMode ? 'dark' : 'default'"
      :body-text-variant="$store.state.tools.darkMode ? 'light' : 'default'"
      no-close-on-backdrop>
      <template #modal-title> Import dongles </template>
      <div>
        <div
          class="forFormFile mt-2 whiteBg"
          :class="{ darkBg: $store.state.tools.darkMode }">
          <b-form-file
            class="cursor"
            accept=".tsv"
            v-model="donglesImport"
            plain></b-form-file>
        </div>

        <div style="display: flex; justify-content: flex-end">
          <Vbutton
            class="mt-2"
            type="edit"
            @clicked="importFile"
            style="display: flex; margin-bottom: 0px">
            <template slot="text">
              <i
                v-if="importLoading"
                class="fa-solid fa-rotate rotate mr-2"
                style="margin-top: 2px"></i>
              <i
                v-else
                class="fa fa-upload mr-2"
                aria-hidden="true"
                style="margin-top: 2px"></i
              >Import
            </template>
          </Vbutton>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vbutton from '@/components/Shared/Vbutton'
import VTable from '@/components/Shared/VTable'
import { dongles } from '@/api/dongles'
import { mapActions, mapGetters } from 'vuex'
import notif from '@/utils/notif'
import DoughnutComponent from '@/components/Charts/DoughnutComponent'

export default {
  components: {
    Vbutton,
    VTable,
    DoughnutComponent
  },
  data() {
    return {
      donglesImport: null,
      importModal: false,
      importLoading: false,
      firstLoading: true,
      orderBy: 'id',
      sortBy: 0,
      donglesData: [],
      search: {},
      totalLoading: false,
      statusDongles: [],
      scopedData: [],
      colors: [
        {
          name: 'imported',
          color: '#2980b9'
        },
        {
          name: 'checked',
          color: '#27ae60'
        },
        {
          name: 'linked',
          color: '#2ecc71'
        },
        {
          name: 'improper',
          color: '#c0392b'
        },
        {
          name: 'return',
          color: '#8e44ad'
        }
      ]
    }
  },
  mounted() {
    this.getDonglesScopedData()
    this.getAllDongles(true)
  },
  computed: {
    ...mapGetters('vueTableFilters', ['getState']),

    filterStatus: {
      get() {
        return this.getState.filters.status ? this.getState.filters.status : ''
      },
      set(value) {
        this.getState.filters.status = value
      }
    }
  },
  methods: {
    ...mapActions('vueTableFilters', ['setFilters']),

    getAllDongles(startLoading) {
      dongles
        .getDongles(
          this.$store.getters['vueTableFilters/getState'].perPages,
          this.$store.getters['vueTableFilters/getState'].page,
          this.$store.getters['vueTableFilters/getState'].ascending ?? this.sortBy,
          { ...this.$store.getters['vueTableFilters/getState'].filters },
          this.$store.getters['vueTableFilters/getState'].orderBy ?? this.orderBy
        )
        .then(response => {
          this.donglesData = response.data
          if (startLoading) {
            this.firstLoading = false
          }
        })
    },
    importFile() {
      this.importLoading = true
      dongles
        .importDongles(this.donglesImport)
        .then(response => {
          this.importLoading = false
          this.donglesImport = false
          this.importModal = false
          notif.showNotif(this.$t('common.success'), 'success')
          this.getAllDongles()
          this.getDonglesScopedData()
        })
        .catch(error => {
          if (error.response.data.errors) {
            error.response.data.errors.forEach(err => {
              notif.showNotif(err, 'error')
            })
          } else {
            notif.showNotif(error.response.data.message, 'error')
          }
          this.importLoading = false
          this.donglesImport = null
        })
    },
    rightClick(element, row) {
      let elements = document.querySelectorAll('.' + element)
      elements.forEach(el => {
        el.setAttribute('href', `/dongles/${row.id}`)
      })
    },
    rowClick(row, middle) {
      let pathLink = `/dongles/${row.id}`

      if (middle) {
        let routeData = this.$router.resolve({
          path: pathLink
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push({
          path: pathLink
        })
      }
    },
    selectSearch(event, key) {
      this.search[key] = event.target.value

      let filtre = {
        ...this.$store.getters['vueTableFilters/getState'].filters,
        ...this.search
      }
      Object.keys(filtre).forEach(key => filtre[key].toString().trim() == '' && delete filtre[key])

      this.setFilters(filtre)
      this.getAllDongles()
    },
    getDonglesScopedData() {
      this.totalLoading = true
      dongles
        .getScopedData()
        .then(response => {
          console.log(response)
          this.scopedData = response.data
          let test = []
          for (let key in this.scopedData.scopeStatus) {
            test.push({
              name: key,
              count: this.scopedData.scopeStatus[key],
              color: this.colors.find(e => e.name == key).color
            })
          }

          this.scopedData.scopeStatus = test
          this.totalLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.forFormFile {
  border-radius: 6px;
  padding: 5px;
  width: 100%;
  margin-right: 20px;
}

.whiteBg {
  background: rgb(240, 240, 240);
}
.darkBg {
  background: rgb(54, 54, 54);
}

.rotate {
  animation: loadingBoxe 1s linear infinite;
}
@keyframes loadingBoxe {
  to {
    transform: rotate(360deg);
  }
}
.logs_imported {
  background: #2980b9;
}

.logs_checked {
  background: #27ae60;
}
.logs_linked {
  background: #2ecc71;
}
.logs_improper {
  background: #c0392b;
}
.logs_return {
  background: #8e44ad;
}
.logs_deleted {
  background: tomato;
}

.insideBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forNodataWrapper {
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.four {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
</style>
