const getDefaultState = () => {
  return {
    subject: null,
    filters: null,
    save: false,
    page: 1,
    orderBy: null,
    ascending: null,
    perPages: 10,
    period: {
      start: null,
      end: null
    },
    duration: {
      sign: null,
      duration: null
    },
    extraFilter: null,
    shopFilters: [],
    profileFilters: []
  }
}

const state = getDefaultState()

const getters = {
  getState(state) {
    return {
      subject: state.subject,
      filters: state.filters,
      save: state.save,
      page: state.page,
      orderBy: state.orderBy,
      ascending: state.ascending,
      perPages: state.perPages,
      period: state.period,
      duration: state.duration,
      extraFilter: state.extraFilter,
      shopFilters: state.shopFilters,
      profileFilters: state.profileFilters
    }
  }
}

const actions = {
  setSubject({ commit }, params) {
    commit('setSubject', params)
  },
  setFilters({ commit }, params) {
    commit('setFilters', params)
  },
  saveFilter({ commit }) {
    commit('toggleSave')
  },
  freeFilter({ commit }) {
    commit('toggleSave')
  },
  setPage({ commit }, params) {
    commit('setPage', params)
  },
  setOrderBy({ commit }, params) {
    commit('setOrderBy', params)
  },
  setAscending({ commit }, params) {
    commit('setAscending', params)
  },
  setPerPages({ commit }, params) {
    commit('setPerPages', params)
  },
  setPeriod({ commit }, period) {
    commit('setPeriod', period)
  },
  setDuration({ commit }, params) {
    commit('setDuration', params)
  },
  resetDuration({ commit }) {
    commit('resetDuration')
  },
  setExtraFilter({ commit }, params) {
    commit('setExtra', params)
  },
  setShopFilter({ commit }, params) {
    commit('setShopFilter', params)
  },
  setProfileFilter({ commit }, params) {
    commit('setProfileFilter', params)
  },
  resetState({ commit }) {
    commit('resetState')
  }
}

const mutations = {
  setSubject(state, params) {
    state.filters = null
    state.save = false
    state.subject = params
  },
  setFilters(state, params) {
    if (params) state.filters = params
  },
  toggleSave(state) {
    state.save = !state.save
  },
  setPage(state, params) {
    state.page = params
  },
  setOrderBy(state, params) {
    state.orderBy = params
  },
  setAscending(state, params) {
    state.ascending = params
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setPerPages(state, params) {
    state.perPages = params
  },
  setPeriod(state, period) {
    state.period.start = period.start
    state.period.end = period.end
  },
  setDuration(state, params) {
    state.duration = params
  },
  resetDuration(state) {
    state.duration.sign = null
    state.duration.duration = null
  },
  setExtra(state, params) {
    state.extraFilter = params
  },
  setShopFilter(state, params) {
    state.shopFilters = params
  },
  setProfileFilter(state, params) {
    state.profileFilters = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
