const getDefaultState = () => {
  return {
    nav: [
      {
        road: '/login',
        login: false
      },
      {
        road: '/password/forgot',
        login: false
      },
      {
        road: '/password/change',
        login: true
      },
      {
        road: '/password/reset/:token',
        login: false
      },
      {
        road: '/404',
        login: false
      },
      {
        road: '/dashboard',
        login: true
      }
    ],
    access: [],
    tipsOrder: true
  }
}

const state = getDefaultState()

const getters = {
  getNav(state) {
    let nav = [...state.nav, ...state.access]

    nav.filter((item, index) => nav.indexOf(item) === index)
    nav.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])

    return nav
  },
  getAccess(state) {
    return state.allow
  },
  getTipsOrder(state) {
    return state.tipsOrder
  }
}

const actions = {
  setNav({ commit }, nav) {
    commit('mergeNav', nav)
  },
  reverseTipsOrder({ commit }) {
    commit('setTipsOrder')
  }
}

const mutations = {
  mergeNav(state, nav) {
    state.access = nav
  },
  setTipsOrder(state) {
    state.tipsOrder = !state.tipsOrder
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
