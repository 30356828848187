import axios from 'axios'
import Vuex from '@/store/index.js'
import router from '@/router'
import notif from '@/utils/notif'
import i18n from '../i18n'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json, charset=utf-8',
    Accept: 'application/json'
  }
})

// Add a request interceptor
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token') || false
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

api.interceptors.response.use(
  response => {
    return response
  },
  err => {
    const originalRequest = err.config

    if (originalRequest.url === '/refresh-token') {
      isRefreshing = false
      if (router.history.current.path != '/login' && router.history.current.path != '/dashboard') {
        sessionStorage.setItem('url', router.history.current.path)
      }
      Vuex.commit('authentification/resetState', null, { root: true })
      router.push({ path: '/login' })
    }

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then(res => {
            sessionStorage.setItem('url', router.history.current.path)
            return api(originalRequest)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(function (resolve, reject) {
        api
          .post('/refresh-token', {
            // refresh_token: Vuex.getters['authentification/getToken'].refresh_token
            refresh_token: localStorage.getItem('refresh_token')
          })
          .then(res => {
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('refresh_token', res.data.refresh_token)
            //  Vuex.commit('authentification/refreshToken', res.data)
            api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
            originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token
            processQueue(null, res.data.token)
            resolve(api(originalRequest))
          })
          .catch(err => {
            processQueue(err, null)
            reject(err)
          })
          .then(() => {
            isRefreshing = false
          })
      })
    }

    return Promise.reject(err)
  }
)

export default api
