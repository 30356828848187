<template>
  <div>
    <router-view></router-view>
    <notifications
      group="dashboard"
      class="my-notification"
      position="top">
      <template
        slot="body"
        slot-scope="props">
        <div :class="props.item.type">
          <div
            class="notif-container"
            :class="{ 'notif-container-d-mode': $store.state.tools.darkMode }">
            <div class="circle">
              <CIcon
                v-if="props.item.type == 'error'"
                name="cil-x" />
              <i
                v-else-if="props.item.type == 'info'"
                class="fa fa-bell" />

              <CIcon
                v-else-if="props.item.type == 'success'"
                name="cil-check" />
              <i
                v-else-if="props.item.type == 'warn'"
                class="fa fa-exclamation" />
            </div>
            <div>
              <article class="title">
                {{ $t(`common.${props.item.type}`) }}
              </article>
              <div class="content">
                {{ props.item.text }}
              </div>
            </div>
            <a
              class="close"
              :class="{ 'close-d-mode': $store.state.tools.darkMode }"
              @click="props.close">
              <CIcon name="cil-x" />
            </a>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import 'regenerator-runtime'
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState('authentification', ['access_token_expiration'])
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
