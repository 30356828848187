export const permissions = {
  read_organizations: 'read organizations',
  create_organizations: 'create organizations',
  update_organizations: 'update organizations',
  delete_organizations: 'delete organizations',
  create_users: 'create users',
  update_users: 'update users',
  read_users: 'read users',
  delete_users: 'delete users',
  create_patients: 'create patients',
  update_patients: 'update patients',
  read_patients: 'read patients',
  delete_patients: 'delete patients',
  read_logs: 'read logs',
  read_boxes: 'read boxes',
  create_boxes: 'create boxes',
  update_boxes: 'update boxes',
  read_subscriptions: 'read subscriptions',
  create_subscriptions: 'create subscriptions',
  update_subscriptions: 'update subscriptions',
  read_algos: 'read algos',
  read_referential: 'read referentials',
  qbo: 'qbo',
  create_bds: 'create bds',
  update_bds: 'update bds',
  read_bds: 'read bds',
  delete_bds: 'delete bds',
  read_opto_patients: 'read opto patients',
  create_estimate: 'create estimate',
  create_invoice: 'create invoice',
  create_customer: 'create customer',
  create_qbo_item: 'create qbo item',
  create_v1_customer: 'create v1 customers',
  read_console_settings: 'read console settings'
}
