import axios from 'axios'

const token = process.env.VUE_APP_OPTOBASE_TOKEN
const http = axios.create({
  baseURL: 'https://optobase.oceanwork.fr/api/v1/',
  headers: { Authorization: `Token ${token}`, 'Content-type': 'application/json' }
})

export const optobase = {
  getDevice,
  search,
  getDongleById,
  getPhotosByDeviceId,
  getConnectorByConnectorId,
  getManualsByManualsId,
  getDongles
}

function getDevice(id) {
  return new Promise((resolve, reject) => {
    http
      .get(`device/?id=${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function search(search) {
  return new Promise((resolve, reject) => {
    http
      .get(`device/?search=${search}&limit=10`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getDongleById(id) {
  return new Promise((resolve, reject) => {
    http
      .get(`dongle/?id=${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getPhotosByDeviceId(id) {
  return new Promise((resolve, reject) => {
    http
      .get(`photo/?device__id=${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getConnectorByConnectorId(id) {
  return new Promise((resolve, reject) => {
    http
      .get(`connector/?id=${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getManualsByManualsId(id) {
  return new Promise((resolve, reject) => {
    http
      .get(`manual/?id=${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getDongles() {
  return new Promise((resolve, reject) => {
    http
      .get(`dongle`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
