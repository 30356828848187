const getDefaultState = () => {
  return {
    currentOrganization: null
  }
}

const state = getDefaultState()

const getters = {
  getState(state) {
    return {
      currentOrganization: state.currentOrganization
    }
  }
}

const actions = {
  setOrga({ commit }, params) {
    commit('set_orga', params)
  }
}

const mutations = {
  set_orga(state, params) {
    state.currentOrganization = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
