import api from '@/api/api'

export const auth = {
  login,
  logout,
  refreshToken,
  changePassword,
  forgotPassword,
  resetPassword,
  updateProfil,
  getCurrentUser,
  addProfile,
  deleteProfilAccount,
  updateProfileAccount,
  addLogo,
  deleteLogo,
  addInformations,
  editInformations
}

function login(email, password) {
  return new Promise((resolve, reject) => {
    api
      .post('/login', {
        email: email,
        password: password
      })
      .then(response => {
        api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function logout() {
  return new Promise((resolve, reject) => {
    api
      .get('/logout')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function refreshToken(refresh_token) {
  return new Promise((resolve, reject) => {
    api
      .post('/refresh-token', { refresh_token: refresh_token })
      .then(response => {
        api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    api
      .post('/password/forget', {
        email
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function changePassword(old_password, password, password_confirmation) {
  return new Promise((resolve, reject) => {
    api
      .post('/password/change', {
        old_password,
        password,
        password_confirmation
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function resetPassword(token, email, password, password_confirmation) {
  return new Promise((resolve, reject) => {
    api
      .post('/password/reset', {
        token,
        email,
        password,
        password_confirmation
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function updateProfil(credentials) {
  return new Promise((resolve, reject) => {
    api
      .put('/user', {
        ...credentials
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function addLogo(form_data) {
  return new Promise((resolve, reject) => {
    api
      .post('user/profile/logo', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (response && response.data && response.data.status == 'Bad request') {
          reject(response)
        } else {
          resolve(response)
        }
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function deleteLogo() {
  return new Promise((resolve, reject) => {
    api
      .post('user/profile/logo/delete')
      .then(response => {
        if (response && response.data && response.data.status == 'Bad request') {
          reject(response)
        } else {
          resolve(response)
        }
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function addProfile(profile) {
  return new Promise((resolve, reject) => {
    api
      .post('/user/profile', {
        ...profile
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function deleteProfilAccount(profile) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/user/profile/${profile.id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function updateProfileAccount(profile) {
  return new Promise((resolve, reject) => {
    api
      .put(`/user/profile/${profile.id}`, {
        ...profile
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    api
      .get(`/user`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function addInformations(informations) {
  return new Promise((resolve, reject) => {
    api
      .post('/user/informations', {
        ...informations
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}

function editInformations(informations) {
  return new Promise((resolve, reject) => {
    api
      .put('/user/informations', {
        ...informations
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error.response)
      })
  })
}
