import Vue from 'vue'

function showNotif(messages, type) {
  if (type != 'success') {
    console.log(type, messages)
  }

  if (typeof messages === 'string') {
    displayNotif(messages, type)
  } else {
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i]
      displayNotif(message, type)
    }
  }
}

function displayNotif(message, type) {
  Vue.notify({
    group: 'dashboard',
    text: message,
    type: type,
    duration: 3000
  })
}

export default {
  showNotif
}
